import $ from '../../Foundation/node_modules/jquery';
import mmenu from '../../Foundation/node_modules/jquery.mmenu'

// MMENU
var $menu = $("#mobile-menu").mmenu({	
   	"extensions": [
	    "position-front",
	    "position-right",
    ],
    "navbars": [
        {
           "position": "bottom",
           "content": [
              "<div class='mmenu__footer'><a href='/#section-homeof' id='footer-link'><span class='homeof__text'>Home of</span><svg class='svg--weightlifting_nw-dims'><use xlink:href='#weightlifting_nw'></use></svg></a></div>",             
           ]
        }
     ]
},{
	"offCanvas": {
        "pageSelector": "#page"
     }
});

var $icon = $("#hamburger");
var API = $menu.data( "mmenu" );

$icon.on( 'click', function() {
   if( $('html').hasClass('mm-wrapper_opened') ){   	
   		$icon.find('.hamburger').removeClass('is-active');
		API.close();		
	}else{
		$icon.find('.hamburger').addClass( 'is-active' );
		API.open();				
	}
});

var $html = $('html, body');
$menu.find( 'li.nav > a' ).on(
    'click',
    function(e)
    {
    	e.preventDefault();
        var href = $(this).attr( 'href' );
        if ( href.indexOf('#') == 1 )
        {
        	API.close();
            setTimeout(
	            function()
	            {	            	
	            	$('html, body').animate({ scrollTop: $( href.substr(1) ).offset().top}, 1000);
	            }, 10
	        );                     
        }
    }
);

$('a.smooth-scroll').on(
    'click',
    function(e)
    {
      e.preventDefault();
        var href = $(this).attr( 'href' );
        if ( href.indexOf('#') == 1 )        {
         
          $('html, body').animate({ scrollTop: $( href.substr(1) ).offset().top}, 1000);
                              
        }
    }
);


$menu.find('#footer-link').on(
    'click',
    function(e)
    {
      e.preventDefault();
        var href = $(this).attr( 'href' );
        if ( href.indexOf('#') == 1 )
        {
            API.close();
            setTimeout(
              function()
              {               
                $('html, body').animate({ scrollTop: $( href.substr(1) ).offset().top}, 1000);
              }, 10
          );                     
        }
    }
);


// API.bind( 'open:finish', function() {
//    setTimeout(function() {
//       $icon.find('.hamburger').addClass( 'is-active' );
//    }, 0);
// });
API.bind( 'close:finish', function() {
   setTimeout(function() {
      $icon.find('.hamburger').removeClass( 'is-active' );
   }, 0);
});