import $ from '../Foundation/node_modules/jquery';

// import $ from 'jquery';
// import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
// window.jQuery = $;
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
require('./lib/mmenu.js');
require('./lib/slick.js');
require('./lib/fancybox.js');

// FULLHEIGHT
if($('.fullheight').length > 0){
    setFullHeight();
    $(window).on('orientationchange, resize', function(event){
        setFullHeight();
    });
}  

// SET FULLHEIGHT
function setFullHeight(){
	var windowHeight = $(window).height();
	var $fullheight = $('.fullheight');
	$fullheight.css('height', windowHeight);     
}

// FOUNDATION
$(document).foundation();

$('.scroll').click(function(e){
    e.preventDefault();
    $('html, body').animate({ scrollTop: $('#section-lofoffun').offset().top}, 1000);
})