import $ from '../../Foundation/node_modules/jquery';
import fancybox from '../../Foundation/node_modules/@fancyapps/fancybox'

// FANCYBOX
$('[data-fancybox]').fancybox({	
	// transitionEffect: "slide",
	infobar: false,
	buttons: [   
		"close"
	],
	btnTpl: {
		arrowLeft:
	      '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}">' +
	      "</button>",

	    arrowRight:
	      '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}">' +
	      "</button>",
	}
});
