import $ from '../../Foundation/node_modules/jquery';
import slick from '../../Foundation/node_modules/slick-carousel'

// SLICK SLIDER
$('.homeslider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    fade: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    pauseOnHover: false,
    pauseOnDotsHover: false,
    autoplay: true,
    autoplaySpeed: 5000,
    customPaging: function(slick, index) {
        var slickNumberPaging = slick.$slides.eq(index).find('.homeslider__slide').data('number') || '';
        return '<button><div class="circle"><svg height="34" width="34"><circle cx="17" cy="17" r="16"></circle></svg></div><span class="circle-number">' + slickNumberPaging + '</span></button>';
    },
});

// about slider
var $about_slider = $('.about-slider');
var about_settings = {
    centerMode: true,
    // centerPadding: '50px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,  
}
$about_slider.slick(about_settings);

// reslick only if it's not slick()
$(window).on('resize', function() {
    if ($(window).width() > 920) {
        if ($about_slider.hasClass('slick-initialized')) {
            $about_slider.slick('unslick');
        }
        return
    }

    if (!$about_slider.hasClass('slick-initialized')) {
        return $about_slider.slick(about_settings);
    }
});

// facility slider
var $facility_slider = $('.facility-slider');
var facility_settings = {
    centerMode: true,
    // centerPadding: '50px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,  
}
$facility_slider.slick(facility_settings);

// reslick only if it's not slick()
$(window).on('resize', function() {
    if ($(window).width() > 920) {
        if ($facility_slider.hasClass('slick-initialized')) {
            $facility_slider.slick('unslick');
        }
        return
    }

    if (!$facility_slider.hasClass('slick-initialized')) {
        return $facility_slider.slick(facility_settings);
    }
});

$('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: false,
    asNavFor: '.slider-nav'
});

$('.slider-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,   
    arrows: false,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
    {
      breakpoint: 470,
      settings: {
        centerMode: true,
        slidesToShow: 2,
      }
    },    
    {
      breakpoint: 400,
      settings: {
        centerMode: true,
        slidesToShow: 2,
      }
    }
  ]
});

var $slick_slider = $('.mobile-slider');
var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px',
}
$slick_slider.slick(settings);

// reslick only if it's not slick()
$(window).on('resize', function() {
    if ($(window).width() > 640) {
        if ($slick_slider.hasClass('slick-initialized')) {
            $slick_slider.slick('unslick');
        }
        return
    }

    if (!$slick_slider.hasClass('slick-initialized')) {
        return $slick_slider.slick(settings);
    }
});
$(window).resize();


$(function() {

    $('.button-switch').find('a').click(function(e){
        e.preventDefault();

        $('.button-switch a').removeClass('button--active');
        $(this).addClass('button--active');

        $('#about-slider, #facility-slider').addClass('hide');
        $( $(this).data('target') ).removeClass('hide');

        if($(this).data('target') == '#facility-slider'){
            $facility_slider.slick('setPosition');
        };
        if($(this).data('target') == '#about-slider'){
            $about_slider.slick('setPosition');
        };
    })

});